<template>
  <div class="cartCard">
    <close-cart-icon @click="removeCartItem(data.id)" class="cartCard__close" />
    <div class="d-flex align-items-center">
      <h2 class="m-0">{{ data.consultation.service.name }}</h2>
      <div class="cartCard__language">{{ data.language.code }}</div>
    </div>
    <div class="cartCard__content">
      <div class="cartCard__info">
        <avatar
          user-role="experts"
          :image-name="data.consultation.expert.image"
          :user-code="data.consultation.expert.code"
          size="100"
          height="60px"
          width="60px"
        />
        <div class="ms-3">
          <div class="cartCard__text cartCard__text-name">
            <short-name
              :name="
                data.consultation.expert.first_name +
                ' ' +
                data.consultation.expert.last_name
              "
            />
          </div>
          <div class="cartCard__text cartCard__text-position">
            {{ data.consultation.expert.position }}
          </div>
        </div>
      </div>
      <div>
        <div class="cartCard__text">{{ data.consultation.name }}</div>
        <div class="cartCard__text">
          {{ $t("duration") }}
          <duration-time :data="data.consultation.duration" />
        </div>
      </div>
      <div class="cartCard__text-width">
        <div class="cartCard__text">
          <data-day :data="data.time" />
        </div>
        <div class="cartCard__time">
          <data-formatted date-format="HH:mm" :data="data.time" />
        </div>
      </div>
      <div class="cartCard__cost">
        <span v-if="data.is_credits_used" class="cartCard__cost--newCost">
          €{{ data.price }}
        </span>
        <span :class="{ 'cartCard__cost--oldCost': data.is_credits_used }">
          €{{
            data.is_credits_used
              ? valueAfterCreditsUse(data.price, data.used_credits)
              : data.price
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/UI/images/Avatar";
import CloseCartIcon from "@/components/UI/icons/CloseCartIcon";
import DurationTime from "@/components/components-helpers/DurationTime";
import ShortName from "@/components/components-helpers/ShortName";
import DataDay from "@/components/components-helpers/DataDay";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";

export default {
  props: {
    data: Object,
    updatedItems: Function,
  },
  name: "CartCard",
  components: {
    DataDay,
    DataFormatted,
    ShortName,
    DurationTime,
    CloseCartIcon,
    Avatar,
  },
  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },
  methods: {
    removeCartItem(itemId) {
      this.$http
        .delete(`/cart/items/${itemId}`)
        .then(() => {
          this.updatedItems();
          this.toast.success(this.$t("ItemWasSuccessfullyDeleted"));
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    valueAfterCreditsUse(price, credits) {
      return Number(price + credits).toFixed(2);
    },
  },
  computed: {
    ...mapGetters({
      cartItems: "CART_ITEMS",
      loginUser: "CURRENT_USER_INFORMATION",
      totalCoast: "TOTAL_COST",
      creditUsed: "CREDIT_USED",
    }),
  },
};
</script>

<style></style>
