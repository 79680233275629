<template>
  <main-layout>
    <div class="calendarPage__titleContainer mb-15">
      <base-button @click="$router.back()" :back="true">
        <horizontal-arrow />
      </base-button>
      <h1 class="calendarPage__title">{{ $t("cartBarTitle") }}</h1>
    </div>
    <div v-if="cartItems.length !== 0">
      <cart-card
        v-for="cart in cartItems"
        :key="cart.id"
        :data="cart"
        :updated-items="updateCartItems"
      />
      <div class="cartPage__totalContainer">
        <div v-if="isCreditUse" class="cartPage__totalTitle">
          {{ creditUsed + " " + $t("CreditsWillBeDebited") }}
        </div>
        <div class="cartPage__total">
          <div>
            <base-button
              class="employeeCompanyCart__assignSelectButton"
              v-if="!isCreditUse"
              @click="showApplyingCreditsPopup()"
              :calendar="true"
            >
              {{ $t("ApplyCredits") }}
            </base-button>
            <div v-if="isCreditUse">
              <div class="cartPage__credit">
                <div>€{{ creditUsed }}</div>
                <span @click="showApplyingCreditsPopup()">{{
                  $t("editExpertBtn")
                }}</span>
              </div>
              <div @click="dropCredit()" class="cartPage__drop">
                {{ $t("DropCredit") }}
              </div>
            </div>
          </div>
          <div class="cartPage__result">
            <div
              class="d-flex justify-content-end"
              :class="{ 'mb-20': isCreditUse }"
            >
              {{ $t("total") }}
              <div
                class="cartPage__total-coast"
                :class="{ 'cartPage__total-coast--stroke': isCreditUse }"
              >
                €{{ totalCoast }}
              </div>
            </div>
            <div v-if="isCreditUse" class="d-flex justify-content-end">
              {{ $t("TotalAfterApplyingCredits") }}
              <div class="cartPage__total-coast cartPage__total-coast--credits">
                €{{ totalCoastAfterUsedCredit }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row cartPage__costRow">
        <div v-if="havePayment" class="accountPage__payment col-12 col-lg-6">
          <h5>{{ $t("accountOverviewPagePayment") }}</h5>
          <div class="accountPage__paymentText">
            {{ $t("accountOverviewPagePaymentText") }}
          </div>
          <div class="accountPage__paymentCart">
            <div class="accountPage__paymentCartWrapper">
              <div class="accountPage__paymentCart-number">
                **** **** **** {{ havePayment }}
              </div>
              <div class="accountPage__paymentCart-small">
                <span class="accountPage__paymentCart-bold"></span>
              </div>
              <div class="accountPage__paymentCart-small">
                <span class="accountPage__paymentCart-bold"></span>
              </div>
            </div>
          </div>
        </div>
        <payment-method v-if="!havePayment" />
        <div class="col-12 col-lg-6">
          <div class="cartPage__costContainer">
            <div class="cartPage__description">
              {{ $t("cartPageDescription") }}
            </div>
            <div class="cartPage__description">
              {{ $t("cartPageDescription2") }}
            </div>
            <div class="cartPage__checkbox">
              <input
                class="cursor-pointer"
                type="checkbox"
                name="accept"
                v-model="checked"
                id="accept"
              />
              <label for="accept"
                >{{ $t("cartPageAgree")
                }}<span class="text-decoration-underline cursor-pointer">{{
                  $t("cartPagePrivacy")
                }}</span>
                {{ $t("and") }}
                <span class="text-decoration-underline cursor-pointer">
                  {{ $t("cartPageTerms") }} </span
                >.</label
              >
            </div>
            <base-button
              :is-loaded="loaded"
              :class="{ 'button-disable': !checked || !havePayment }"
              @click="bookAppointments"
              margin-left="0"
              :calendar="true"
              >{{ $t("bookAppointment") }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="cartPage__noItems">
      <h4>{{ $t("emptyCart") }}</h4>
      <div>
        <p>{{ $t("lookLikeYou") }}</p>
        <p>
          {{ $t("goTo")
          }}<span class="weight-600">{{ $t("headerCalendarBtn") }}</span
          >{{ $t("toBook") }}
        </p>
      </div>
      <div>
        <base-button link="/calendar" :calendar="true">
          <template #icon>
            <calendar-icon />
          </template>
          {{ $t("headerCalendarBtn") }}
        </base-button>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import CartCard from "@/components/elements/card/CartCard";
import BaseButton from "@/components/UI/buttons/BaseButton";
import BaseInput from "@/components/UI/inputs/BaseInput";
import { mapGetters } from "vuex";
import CalendarIcon from "@/components/UI/icons/CalendarIcon";
import PaymentMethod from "@/components/elements/card/PaymentMethod";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import { useToast } from "vue-toastification";

export default {
  name: "CartPage",
  components: {
    HorizontalArrow,
    PaymentMethod,
    CalendarIcon,
    BaseInput,
    BaseButton,
    CartCard,
    MainLayout,
  },
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  data() {
    return {
      loaded: false,
      isCreditUse: false,
      cardValues: {
        exp: "",
        exp_month: "",
        exp_year: "",
        number: "",
        cvc: "",
      },
      addPayment: false,
      checked: false,
    };
  },
  mounted() {
    this.getPaymentMethod();
  },
  methods: {
    bookAppointments() {
      this.loaded = true;
      if (this.checked && this.havePayment) {
        this.$http
          .post("/cart/book")
          .then(({ data }) => {
            this.updateCreditBalance();
            this.loaded = false;
            this.$store.commit("SET_CART_ITEMS", []);
            this.$store.commit("SET_BOOKED_STATUS", data.body);
            this.$router.push("/booked-status");
          })
          .catch((err) => {
            if (err.response.data.status === 400) {
              this.toast.error(err.response.data.message);
            } else {
              this.toast.error(this.$t("NetworkError"));
            }
          });
      }
    },
    getPaymentMethod() {
      if (!this.havePayment) {
        this.$http.get("/employees/account/card").then(({ data }) => {
          this.$store.commit("SET_PAYMENT", data.body);
        });
      }
    },
    showApplyingCreditsPopup() {
      this.$store.commit("SET_APPLYING_CREDITS", true);
    },
    dropCredit() {
      this.$http
        .patch("/cart/balance", {
          amount: 0,
        })
        .then(() => {
          this.$store.commit("SET_CREDIT_USED", false);
          this.isCreditUse = false;
          this.updateCartItems();
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    updateCartItems() {
      this.$http.get("/cart/items").then(({ data }) => {
        this.$store.commit("SET_CART_ITEMS", data.body);
      });
    },
    updateCart() {
      this.$http.get("/cart").then(({ data }) => {
        this.isCreditUse = data.body.used_credits !== 0;
        let coastAfterCredits =
          data.body.used_credits === 0 ? data.body.total : data.body.full_total;
        this.$store.commit("SET_CREDIT_USED", data.body.used_credits);
        this.$store.commit("SET_TOTAL_COST", coastAfterCredits);
        this.$store.commit("SET_TOTAL_COST_AFTER_USED_CREDIT", data.body.total);
        if (data.body.used_credits > 0) {
          this.isCreditUse = true;
        }
      });
    },
    updateCreditBalance() {
      this.$http.get("/employees/balance").then(({ data }) => {
        this.$store.commit("SET_CREDITS", data.body.credits);
      });
    },
  },
  computed: {
    ...mapGetters({
      creditsValue: "CREDITS",
      loginUser: "CURRENT_USER_INFORMATION",
      cartItems: "CART_ITEMS",
      havePayment: "PAYMENT",
      totalCoast: "TOTAL_COST",
      totalCoastAfterUsedCredit: "TOTAL_COST_AFTER_USED_CREDIT",
      creditUsed: "CREDIT_USED",
    }),
  },
  watch: {
    cartItems: {
      deep: true,
      handler(value) {
        if (value?.length > 0) this.updateCart();
      },
    },
  },
};
</script>

<style></style>
