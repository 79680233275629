<template>
  <div class="col-12 col-lg-6">
    <div v-if="!addPayment" class="cartPage__nonCard">
      <h5>{{ $t("accountOverviewPagePayment") }}</h5>
      <div class="cartPage__nonCardText">
        {{ $t("cartEditAddCart") }}
      </div>
      <div class="cartPage__nonCardSubtitle">
        {{ $t("cartEditAddCartSubtitle") }}
      </div>
      <div class="w-max-content">
        <base-button @click="addPayment = !addPayment" padding="10px 25px">
          {{ $t("cartEditAddPayment") }}
        </base-button>
      </div>
    </div>
    <div v-if="addPayment" class="cartPage__costAddCard">
      <h5>{{ $t("accountOverviewPagePayment") }}</h5>
      <div class="cartPage__nonCardText">
        {{ $t("cartEditAddCart") }}
      </div>
      <div class="cartPage__nonCardSubtitle">
        {{ $t("cartEditAddCartSubtitle") }}
      </div>
      <div class="cartPage__nonCardText">
        {{ $t("cartEditAddBilling") }}
      </div>
      <div class="registrationPage__card cartPage__costAddCardCart">
        <base-input
          v-model="cardValues.number"
          mask="####-####-####-####"
          class="w-100 registrationPage__cardInput"
        >
          {{ $t("cartNumber") }}
        </base-input>
        <div class="d-flex justify-content-between registrationPage__input">
          <div class="accountPage__input-small registrationPage__cardInput">
            <base-input mask="##/####" v-model="cardValues.exp" class="w-100">
              {{ $t("accountOverviewPagePaymentExpire") }}
            </base-input>
          </div>
          <div class="accountPage__input-small registrationPage__cardInput">
            <base-input
              mask="####"
              v-model="cardValues.cvc"
              class="w-100"
              :maxlength="3"
              >CVC/CVV
            </base-input>
          </div>
        </div>
      </div>
      <div class="cartPage__costAddCardBtn">
        <base-button
          @click="addPaymentMethod()"
          :is-loaded="loaded"
          padding="10px 25px"
          >{{ $t("cartEditSave") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton";
import BaseInput from "@/components/UI/inputs/BaseInput";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";

export default {
  name: "PaymentMethod",
  components: { BaseInput, BaseButton },
  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },
  data() {
    return {
      loaded: false,
      cardValues: {
        exp: "",
        exp_month: "",
        exp_year: "",
        number: "",
        cvc: "",
      },
      addPayment: false,
      havePayment: false,
      checked: false,
    };
  },
  methods: {
    addPaymentMethod() {
      this.loaded = true;
      const cardData = { ...this.cardValues };
      cardData.number = cardData.number.replace(/\D/g, "");
      cardData.exp_month = Number(cardData.exp.slice(0, 2));
      cardData.exp_year =
        cardData.exp.slice(3).length === 2
          ? Number(2000 + Number(cardData.exp.slice(3)))
          : Number(cardData.exp.slice(3));
      this.$http
        .post(`employees/attach-card`, cardData)
        .then(() => {
          this.loaded = false;
          this.toast.success(this.$t("successfulAddPaymentMethod"));
          this.$store.commit("SET_PAYMENT", this.cardValues.number.slice(15));
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
