<template>
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14.5" cy="15.5" r="12.5" fill="#39C0ED"/>
        <path d="M8.5 21.5L20.5 9.5" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M20.5 21.5L8.5 9.5" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
</template>

<script>
export default {
    name: "CloseCartIcon"
}
</script>

<style>

</style>
